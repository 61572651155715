import { BrandTypes, BRAND_CONFIG } from '../common/utils/BrandConfig';
import {
  DEFAULT_THEME,
  ThemeProvider,
  AVMED_THEME,
} from '@sentaraui/optimahealth_web';
import { createContext, useContext, useMemo } from 'react';

export enum Brands {
  AVMED = 'AVMED',
  DEFAULT = 'DEFAULT',
}

type BrandProviderType = {
  brand: Brands;
  brandConfig: BrandTypes;
};

interface Props {
  children?: React.ReactNode;
}

export const BrandingContext = createContext<BrandProviderType>({
  brand: Brands.DEFAULT,
  brandConfig: {},
} as any);

const getCurrentHost = (): string => {
  const { hostname, port } = window.location;
  return port ? `${hostname}:${port}` : hostname;
};

const isBrand = (brand: Brands): boolean => {
  const hostName = getCurrentHost();
  return BRAND_CONFIG[brand].hostNames?.includes(hostName) || false;
};

const getBrand = (): Brands => {
  return isBrand(Brands.AVMED) ? Brands.AVMED : Brands.DEFAULT;
};

const themes: Record<Brands, typeof DEFAULT_THEME> = {
  [Brands.AVMED]: AVMED_THEME,
  [Brands.DEFAULT]: DEFAULT_THEME,
};

const getTheme = (brand: Brands) => {
  return themes[brand];
};

export const BrandingProvider = ({ children }: Props) => {
  const brand = getBrand();
  const theme = getTheme(brand);
  const brandConfig = BRAND_CONFIG[brand];

  const value = useMemo(
    () => ({
      brandConfig,
      brand,
    }),
    [brandConfig, brand]
  );

  return (
    <BrandingContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </BrandingContext.Provider>
  );
};

export const useBranding = () => {
  return useContext(BrandingContext);
};

export const isAvMed = () => getBrand() === Brands.AVMED; // Global helper to check if the brand is AVMED or Not
