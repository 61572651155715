import React, { useState } from 'react';
import { t } from 'i18next';
import {
  Button,
  ModalOverlayWithoutClose,
  Headings,
  Paragraph,
} from '@sentaraui/optimahealth_web';
import { useMyDelete } from '@sentara/sentara-api-hooks-core';
import { DeleteAccountPopup } from 'templates';
import { signIn, footerContactUs, errorhandler } from 'common';

const DeleteProfile = () => {
  const { MyDeleteType } = useMyDelete();
  const LINK_BTN_BLOCK_MOB = 'btn-links darkblue-color ps-0 mb-2';
  const PRIMARY_BTN_BLOCK_MOB = 'primary_btn';
  const [isSuccessPopup, setIsSuccessPopup] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowContent, setIsShowContent] = useState<boolean>(false);

  // Function to handle account deletion
  const deleteAccount = async () => {
    setIsShowContent(false);
    setIsConfirmOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  // Function to open the delete account modal
  const submitDelete = (e: any) => {
    setIsOpen(true);
    setIsShowContent(true);
    setIsConfirmOpen(false);
  };

  function closePopUpModal() {
    setIsSuccessPopup(false);
    localStorage.clear();
    window.location.href = signIn;
  }

  const confirmDeleteAccount = async () => {
      const response = await MyDeleteType();
      if (response.errorCode) {
        errorhandler(response?.errorCode);
      } else {
        setIsOpen(false);
        setIsConfirmOpen(false);
        setIsSuccessPopup(true);
      }
  };

  return (
    <>
      {/* Delete account start */}
      <div className="set_my_preferences">
        <div className="pb-2">
          <div className="border border-clr p-4 mb-4 delete_account_border">
            <div className="heading_redhat_24 mb-4">
              <Headings
                className="redhat_bold font-twentyfour fw-700 primary-color mb-4"
                level={4}
                text={t('settings.label.deleteAccount')}
                data-testid="profileDeletAccountHeading"
              />
            </div>
            {/* Render confirmation message and button to delete account */}
            <Paragraph
              className="pb-3 mb-2"
              data-testid="confirmationDeleteAccountInfo"
            >   
             <>
            {t('settings.label.confirmationDeleteAccountInfo')}
            <a
              className="gold-link"
              data-testid="customerSupportLink"
              href={footerContactUs}
            >
              {' '}
              {t('settings.label.customerSupportLink')}
            </a>
          </>
            </Paragraph>
            <Button
              dataTestId={'deleteAccountButton'}
              variant={LINK_BTN_BLOCK_MOB}
              onClick={(e: any) => {
                e.preventDefault();
                submitDelete(e);
              }}
            >
              {t('settings.label.continueToDeleteAccount')}
            </Button>
          </div>
        </div>
      </div>
      {/* Render the DeleteAccountPopup component */} 
      <DeleteAccountPopup
        isOpen={isOpen}
        closeModal={closeModal}
        isShowContent={isShowContent}
        variant={PRIMARY_BTN_BLOCK_MOB}
        deleteAccount={deleteAccount}
        isConfirmOpen={isConfirmOpen}
        confirmDeleteAccount={confirmDeleteAccount}
      />
        <ModalOverlayWithoutClose
          isOpen={isSuccessPopup}
          overlayInfo="Modal information"
        >
          <div
            data-testid="accountDeleteSuccessModal"
            className="d-flex flex-column align-items-center"
          >
            <h5 data-testid="loggedMessage" className="label_logged_message">
              {t('settings.label.accountDeleteSuccessMsg1')}
            </h5>
            <br />
            <Button
              dataTestId="okButton"
              className="primary_btn btn_block_mob"
              onClick={closePopUpModal}
            >
              {t('appModel.label.ok')}
            </Button>
          </div>
        </ModalOverlayWithoutClose>
      {/* Delete account end */} 
    </>
  );
};

export default DeleteProfile;