import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
  RightArrowIcon,
  toSentancePascalCase,
  UserDetails,
  DeviceTypes,
  useDeviceType,
  signIn,
  getDecryptedValue,
  setEncryptedValue,
  claimsSummary,
  internalServerErrorCode,
  CommonErrorMessage,
  convertStringToDate,
  convertToDate,
  getCache,
  deleteEncryptedValue,
  Proxy,
  getLocalStorageBoolean,
  backToDashboardLink,
  apiResponseSafeCheck,
  paySentaraBillURL,
} from 'common';
import {
  useMyDepedentAccess,
  useMemberIDCard,
  useMedicalClaimList,
  useGetDependentAccess,
  useMedicalClaimEOB,
} from '@sentara/sentara-api-hooks-core';

import { YearDropdown } from 'templates';
import { GlobalContext, isAvMed } from 'context';
import {
  Button,
  Headings,
  Label,
  Checkbox,
  DownloadIcon,
  Accordion,
  ContactInfo,
  Loader,
  Link,
  Table,
  Paragraph,
} from '@sentaraui/optimahealth_web';
import { DatePicker } from '../forms/DatePicker';
import { useForm, FormProvider } from 'react-hook-form';

const MedicalClaims: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const darkbluecolor = '#0E406A';
  const year: number = new Date().getFullYear();
  const [membersTable, setMembersTable] = useState<any>([]);
  const [medicalClaimsTable, setMedicalClaimsTable] = useState<any>([]);
  const { loginMID, serviceNumber, primaryUser, getFeature } =
    useContext(GlobalContext);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [toDate, setToDate] = useState<any>([]);
  const [fromDate, setFromDate] = useState<any>([]);
  const [getPhone, setPhone] = useState<any>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [showMedical, setShowMedical] = useState(false);
  const [showSpouse, setShowSpouse] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [memberId, setMemberId] = useState<any>(null);
  const [dependentAccess, setDependentAccess] = useState(false);
  const [invalidDateFormat, setInvalidDateFormat] = useState(false);
  const [emptyDate, setEmptyDate] = useState(false);
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [showParent, setShowParent] = useState(false);
  const { memberIDCardType } = useMemberIDCard();
  const { medicalClaimListType } = useMedicalClaimList();
  const { GetDependentAccessType } = useGetDependentAccess();
  const { MyDepedentAccessType } = useMyDepedentAccess();
  const { MedicalClaimEOBType } = useMedicalClaimEOB();
  const [loader, setLoader] = useState(false);
  const [sortField, setSortField] = useState('');
  const [yearValue, setYearValue] = useState<any>(year);
  const [order, setOrder] = useState('asc');
  const location = useLocation();
  const pageLimit = 10;
  const behaviourlClaims = 'behavioral-claims';
  const medicalClaims = 'medical-claims';
  const methods = useForm({
    mode: 'onChange',
  });
  const { handleSubmit, watch, setValue, getValues } = methods;

  useEffect(() => {
    setFromDate(getValues('from'));
    setToDate(getValues('to'));
  }, [watch('from'), watch('to')]);

  // Function to handle sorting change
  const handleSortingChange = (accessor: string) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  // Function to handle sorting
  const handleSorting = (sortField: string, sortOrder: string) => {
    if (sortField) {
      const sorted: Array<any> = medicalClaimsTable?.predectiveSearches?.sort(
        (a: any, b: any) => {
          if (a[sortField] === null) {
            return 1;
          }
          if (b[sortField] === null) {
            return -1;
          }
          if (a[sortField] === null && b[sortField] === null) {
            return 0;
          }
          return (
            String(a[sortField]).localeCompare(String(b[sortField]), 'en', {
              numeric: true,
            }) * (sortOrder === 'asc' ? 1 : -1)
          );
        }
      );
      setMedicalClaimsTable({
        predectiveSearches: sorted,
        totalcount: medicalClaimsTable?.totalcount,
      });
    }
  };

  //Fetching membersData List
  async function membersData() {
    setLoader(true);
    const result = await memberIDCardType('claims', getCache());
    if (result?.data?.members) {
      setMembersTable(result?.data.members);

      //To display the Allow parent/spouse text
      if (result?.data.parentPermission === true) {
        setShowParent(true);
      } else if (result?.data.spoucePermission === true) {
        setShowSpouse(true);
      }
    } else if (result?.errorCode == internalServerErrorCode) {
      setLoader(false);
      setErrorMessage(t('internalServerError') || '');
    } else if (result?.error) {
      erroHandling(result?.error);
    }

    setPhone(JSON.parse(serviceNumber || '{}'));
  }

  // Fetching mediacal Claim List API
  const dependentDetails = async (mid?: string, resetPagination?: number) => {
    setLoader(true);
    setDateData(mid);
    const offSet = searchParams.get('offset')
      ? Number(searchParams.get('offset'))
      : currentPage;
    const currentPageNumber = resetPagination ?? offSet;
    setCurrentPage(currentPageNumber);

    let userMemberId = mid ?? memberId;
    const result = await medicalClaimListType(
      {
        startDate: searchParams.get('startDate')
          ? searchParams.get('startDate')
          : fromDate,
        endDate: searchParams.get('toDate')
          ? searchParams.get('toDate')
          : toDate,
        memberId: searchParams.get('memberId')
          ? searchParams.get('memberId')
          : userMemberId,
        claimtype: showMedical ? '0' : '1',
        offset: currentPageNumber,
        limit: 10,
      },
      getCache()
    );
    dependentDetailsData(result);
  };
  const setDateData = (mid?: string) => {
    setMemberId(
      searchParams.get('memberId')
        ? searchParams.get('memberId')
        : mid ?? memberId
    );
    setFromDate(
      searchParams.get('startDate') ? searchParams.get('startDate') : fromDate
    );
    setToDate(
      searchParams.get('endDate') ? searchParams.get('endDate') : toDate
    );
  };

  const dependentDetailsData = (result: any) => {
    if (result?.data) {
      setLoader(false);

      setMedicalClaimsTable(result?.data);
      setSearchParams({});
    } else if (result?.error || result?.data !== undefined) {
      setMedicalClaimsTable(result?.data);
      setLoader(false);
      setTotalItems(medicalClaimsTable?.totalcount);
      erroHandling(result?.error);
    }
  };

  //Error handling for 401 || 440 error code, naviagte user to logout
  const erroHandling = (error: any) => {
    if (
      error === 'Request failed with status code 401' ||
      error === 'Request failed with status code 440'
    ) {
      localStorage.clear();
      deleteEncryptedValue();
      window.location.href = signIn;
    }
  };

  // Date validation logic
  const onSubmit = (data: any) => {
    setInvalidDateFormat(false);
    setEmptyDate(false);
    setInvalidDateRange(false);
    const fromDateLength = data?.from?.length > 0 && data?.from?.length < 10;

    const toDateLength = data?.to?.length > 0 && data?.to?.length < 10;
    const formattedFromDate = convertStringToDate(data?.from);
    const formattedToDate = convertStringToDate(data?.to);

    if (
      fromDateLength ||
      toDateLength ||
      formattedFromDate === 'Invalid date' ||
      formattedToDate === 'Invalid date'
    ) {
      setInvalidDateFormat(true);
      setEmptyDate(false);
      setInvalidDateRange(false);
    } else if (data?.from === '' || data?.to === '') {
      setEmptyDate(true);
      setInvalidDateFormat(false);
      setInvalidDateRange(false);
    } else if (
      new Date(data?.from) > new Date(data?.to) ||
      new Date() < new Date(data?.to)
    ) {
      setInvalidDateRange(true);
      setEmptyDate(false);
      setInvalidDateFormat(false);
    } else {
      dependentDetails(undefined, 1);
    }
  };

  // Generate PDF for EOB based on claim data
  const eobPDF = async (data: any) => {
    setLoader(true);
    const postData = {
      claimID: data,
      claimType: showMedical ? '0' : '1',
      memberId: memberId,
    };
    const response = await MedicalClaimEOBType(postData, getCache());
    if (response?.data !== undefined) {
      pdfConverter(response, data);
    } else if (response?.error) {
      setLoader(false);
    }
  };

  // Function to generate PDF from data
  const pdfConverter = (data: any, fileName: any) => {
    const filename = fileName;
    const binary = atob(data && data?.data?.replace(/([^\r])\n/g, '$1\r\n'));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    } // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: 'application/pdf' });
    saveAs(blob, filename);
    setLoader(false);
  };
  useEffect(() => {
    (async () => {
      if (location.pathname.endsWith(medicalClaims)) {
        setShowMedical(true);
      } else if (location.pathname.endsWith(behaviourlClaims)) {
        setShowMedical(false);
      }
      setDefaultDate();
      setInvalidDateFormat(false);
      setInvalidDateRange(false);
      setEmptyDate(false);
      await membersData();
      const response = await GetDependentAccessType();
      setDependentAccess(response?.data?.isDependentAccess || false);
    })();
  }, [location.pathname]);

  const setDefaultDate = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 18);
    const fromdate = convertToDate(currentDate);
    const todate = convertToDate(new Date());
    setToDate(todate);
    setFromDate(fromdate);
    setInvalidDateFormat(false);
    setEmptyDate(false);
    setInvalidDateRange(false);
    setValue('from', fromdate);
    setValue('to', todate);
  };

  const setMemberDetails = async (MID: string) => {
    setMemberId(MID);
    setEncryptedValue(UserDetails.memberIdData, MID);
  };

  useEffect(() => {
    setDefaultDate();
    if (!getLocalStorageBoolean(backToDashboardLink)) {
      setMemberDetails(loginMID);
    }
  }, [loginMID]);

  useEffect(() => {
    (async () => {
      if (membersTable?.length > 0) {
        if (getLocalStorageBoolean(backToDashboardLink)) {
          let ProxyId = await getDecryptedValue(Proxy.memberProxy);
          ProxyId = ProxyId ? JSON.parse(ProxyId) : '';
          const decryptedValue = ProxyId.MemberId;
          let filteredMid = membersTable.filter(
            (item: { memberId: string }) => item.memberId === decryptedValue
          );
          const MID = filteredMid[0]?.mId;
          if (MID) {
            setMemberDetails(MID);
            dependentDetails(MID);
          }
        } else {
          dependentDetails();
        }
      }
    })();
  }, [membersTable, currentPage, showMedical]);

  useEffect(() => {
    if (medicalClaimsTable?.predectiveSearches?.length > 0) {
      setTotalItems(medicalClaimsTable?.totalcount);
      setMaxPages(Math.ceil(medicalClaimsTable?.totalcount / pageLimit));
    } else {
      setTotalItems(medicalClaimsTable?.totalcount);
    }
  }, [medicalClaimsTable, medicalClaimsTable?.predectiveSearches?.length]);

  // Function to toggle checkAccess state and check member access
  const updateDependents = (isChecked: boolean) => {
    setDependentAccess(isChecked);
    checkMemberAccess(isChecked);
  };

  async function checkMemberAccess(value: boolean) {
    setLoader(true);
    const data = (await getDecryptedValue(UserDetails.usedId)) || '';
    await MyDepedentAccessType({
      allowAccess: value,
      userId: data,
    });
    setLoader(false);
  }

  const nextPage = (e: any) => {
    e.preventDefault();

    if (currentPage <= maxPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = (e: any) => {
    e.preventDefault();
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (location.pathname === '/home/medical-claims') {
      localStorage.removeItem('selectedOption');
    }
  });

  // Render error message as response
  function renderErrorMessage() {
    if (invalidDateFormat) {
      return (
        <div className="text-danger redhat_bold">
          {t('claims.label.invalidDateFormat')}
        </div>
      );
    } else if (emptyDate) {
      return (
        <div className="text-danger redhat_bold">
          {t('claims.label.emptyDate')}
        </div>
      );
    } else if (invalidDateRange) {
      return (
        <div className="text-danger redhat_bold">
          {t('claims.label.invalidDateRange')}
        </div>
      );
    } else {
      return null;
    }
  }
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  return (
    <>
      {/* This section should diaply only for medical screen not for behavioural */}
      {loader && <Loader />}
      <div className="not-print">
        <div className="row not-print justify-content-between align-items-center">
          <div className="col-lg-auto col-md-aut0 col-sm-12 col-12 flex-1 mb-4">
            <div className="heading-one-semibold">
              <Headings
                level={1}
                text={
                  showMedical
                    ? t('claims.label.medcialClaimTitle')
                    : t('claims.label.behavioralClaimsTitle')
                }
                dataTestId={
                  showMedical
                    ? 'medicalClaimsHeader'
                    : 'behavioralClaimsHeading'
                }
              />
            </div>
          </div>
          {showMedical && getFeature?.ViewTaxYearSummary && (
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
              <YearDropdown
                setMemberValue={() => {}}
                yearValue={yearValue}
                setYearValue={setYearValue}
                fromMedical={true}
              />
            </div>
          )}
        </div>

        {showMedical && (
          <>
            {(showSpouse || showParent) &&
              getFeature?.Allowspouseviewclaims && (
                <div className="mb-4">
                  <div className="d-flex align-items-center">
                    <Checkbox
                      id={
                        showSpouse ? 'spouseAllowAccess' : 'parentAllowAccess'
                      }
                      className="form-check-input mt-0"
                      type="checkbox"
                      value={dependentAccess}
                      dataTestId={
                        showSpouse
                          ? 'spouseAllowAccessCheckbox'
                          : 'parentAllowAccessCheckbox'
                      }
                      checked={dependentAccess}
                      onChange={(e: any) => updateDependents(e.target.checked)}
                    />
                    <Label
                      className="ps-2 ms-1 font-fourteen"
                      children={
                        showSpouse
                          ? t('claims.label.allowAccessSpouse')
                          : t('claims.label.allowAccessParent')
                      }
                      dataTestId={
                        showSpouse
                          ? 'spouseAllowAccessLabel'
                          : 'parentAllowAccessCheckbox'
                      }
                    />
                  </div>
                </div>
              )}
            {!errorMessage && membersTable?.length > 0 && (
              <Table
                className="medical-claims-table mb-4"
                headerChildren={
                  <>
                    <th
                      data-testid="selectOneTableHeading"
                      className="text-center"
                    >
                      {t('authorization.label.selectOne')}
                    </th>
                    <th data-testid="viewClaimsForTableHeading">
                      {t('claims.label.viewClaimsfor')}
                    </th>
                    <th data-testid="memberIdTableHeading">
                      {t('authorization.label.memberID')}
                    </th>
                  </>
                }
                rowsChildren={
                  <>
                    {membersTable?.length > 0 &&
                      membersTable.map((item: any, i: any) => {
                        return [
                          <tr key={item.mId}>
                            <td className="text-center">
                              <Checkbox
                                type="radio"
                                id={`member${i + 1}`}
                                className="form-check-input mt-0"
                                dataTestId={`medicalClaimCheckbox${i + 1}`}
                                name={'members'}
                                value={item.mId}
                                checked={memberId === item.mId}
                                onChange={() => {
                                  setMemberId(item.mId);
                                  setEncryptedValue('memberIdData', item.mId);
                                }}
                              />
                            </td>
                            <td
                              className="redhat_bold fw-700"
                              data-testid={`viewClaimFor${i + 1}`}
                            >
                              {toSentancePascalCase(item.memberName)}
                              <span className="redhat_regular">
                                {primaryUser === 'P' && loginMID === item.mId
                                  ? ' (' + t('claims.label.primary') + ')'
                                  : ''}
                              </span>
                            </td>
                            <td data-testid={`memberID${i + 1}`}>
                              {item.memberId}
                            </td>
                          </tr>,
                        ];
                      })}
                  </>
                }
              />
            )}
          </>
        )}
      </div>
      <FormProvider {...methods}>
        <form
          data-testid="submit_form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          {/* DATE PICKEK SHOULD COME EHERE */}
          {!errorMessage && (
            <>
              <div>
                <div
                  data-testid="serachDateHeader"
                  className="redhat_bold fw-700 mb-2"
                >
                  <Paragraph>{t('claims.label.date')}</Paragraph>
                </div>
                {renderErrorMessage()}
                <div className="row align-items-end">
                  <div className="mb-4 mb-lg-4 col-lg-5 col-md-5 col-sm-12 col-12">
                    <Label
                      className="pb-1"
                      htmlFor={''}
                      dataTestId="selectDateFromLabel"
                      children={t('claims.label.From')}
                    />
                    <DatePicker
                      fieldName="from"
                      dobValue={fromDate}
                      required={false} //validation is handled separate
                    />
                  </div>
                  <div className="mb-4 col-lg-5 col-md-5 col-sm-12 col-12">
                    <Label
                      className="pb-1"
                      htmlFor={''}
                      fieldName="to"
                      dataTestId="selectDateToLabel"
                      children={t('claims.label.To')}
                    />
                    <DatePicker
                      fieldName="to"
                      dobValue={toDate}
                      required={false} //validation is handled separate
                    />
                  </div>
                  <div className="mb-4 col-lg-2 col-md-2 col-sm-12 col-12">
                    <Button
                      className="secondary_btn btn-medium mw-auto w-100"
                      onClick={(e: any) => {
                        e.preventDefault();
                        setDefaultDate();
                      }}
                      dataTestId="clearButton"
                    >
                      {t('claims.label.clear')}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex-display mb-4">
                <Button
                  dataTestId="viewClaimButton"
                  // onClick={(e: any) => {
                  //   dateValidation(e);
                  // }}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  type="submit"
                  variant={'primary_btn mw-auto btn_block_mob btn-medium'}
                >
                  {t('claims.label.applyFilters')}
                </Button>
                {getFeature?.PayYourSentaraBill
                  ? <div className='ml-2'>
                    <Button
                      variant={'primary_btn btn-medium'}
                      dataTestId={'PaySentaraMedicalBill'}
                      onClick={(e: any) => {
                        e.preventDefault();
                        navigate(paySentaraBillURL);
                      }}
                    >
                      {t('claimsSummary.label.paySentaraMedicalBill')}
                    </Button>
                  </div>
                  : null
                }
              </div>
              <div
                data-testid="medicalExplanations"
                className="redhat_bold fw-700 black-color mb-4"
              >
                <Paragraph>
                  {showMedical
                    ? t('claims.label.medicalExplanationss')
                    : t('claims.label.behavaiour')}
                </Paragraph>
              </div>
            </>
          )}
        </form>
      </FormProvider>
      {!isMobile && !errorMessage && (
        <div className="table-overflow-scroll">
          <Table
            className="claim-explanation-table black-color mb-0 print table-responsive"
            id={'viewauthorizationDetails'}
            headerChildren={
              <>
                <th data-testid="claimIdTab">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0"
                    onClick={() => handleSortingChange('claimId')}
                  >
                    {t('claims.label.id')}
                  </button>
                </th>
                <th data-testid="serviceDateTab">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0"
                    onClick={() => handleSortingChange('serviceDate')}
                  >
                    {t('claims.label.serviceDate')}
                  </button>
                </th>
                <th data-testid="statusTab">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0"
                    onClick={() => handleSortingChange('status')}
                  >
                    {t('claims.label.claimStatus')}
                  </button>
                </th>
                <th data-testid="providerNameTab">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0"
                    onClick={() => handleSortingChange('providerName')}
                  >
                    {t('claims.label.providerName')}
                  </button>
                </th>
                <th data-testid="viewEODTab" className="text-center">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0"
                    onClick={() => handleSortingChange('eob')}
                  >
                    {t('claims.label.vieweob')}
                  </button>
                </th>
              </>
            }
            rowsChildren={
              <>
                {medicalClaimsTable &&
                  medicalClaimsTable.predectiveSearches?.length > 0 &&
                  medicalClaimsTable?.predectiveSearches?.map(
                    (item: any, i: any) => {
                      return [
                        <tr key={item.claimId}>
                          <td data-testid={`claimIdLink${i}`}>
                            <Button
                              variant="darkblue-color bg-transparent border-0 text-decoration-underline p-0"
                              onClick={() => {
                                setSearchParams({
                                  startDate: fromDate,
                                  endDate: toDate,
                                  memberId: memberId,
                                  claimtype: showMedical ? '0' : '1',
                                  offset: String(currentPage),
                                  limit: '10',
                                });
                                navigate(claimsSummary, {
                                  state: {
                                    memberId: memberId,
                                    ClaimType: showMedical ? '0' : '1',
                                    endDate: toDate,
                                    startDate: fromDate,
                                    ClaimId: item.claimId,
                                    isEOB: item.eob,
                                    currentPage,
                                  },
                                });
                              }}
                              dataTestId={`claimIdLinkA${i}`}
                            >
                              {item.claimId}
                            </Button>
                          </td>

                          <td data-testid={`medicalClaimsServiceDate${i}`}>
                            {convertStringToDate(item.serviceDate)}
                          </td>
                          <td data-testid={`medicalClaimStatus${i}`}>
                            {item.status}
                          </td>
                          <td data-testid={`providerName${i}`}>
                            {item.providerName}
                          </td>
                          <td
                            className="text-center"
                            data-testid={`viewEOBIcon${i}`}
                          >
                            {item.eob === 'true' ? (
                              <button
                                className="darkblue-color btn-links"
                                data-testid={`viewEOBButton${i}`}
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  eobPDF(item.claimId);
                                }}
                              >
                                <DownloadIcon color={darkbluecolor} />
                              </button>
                            ) : (
                              <span
                                className="visually-hidden"
                                aria-label="Download not available"
                              >
                                {t('claims.label.notApplicable')}
                              </span>
                            )}
                          </td>
                        </tr>,
                      ];
                    }
                  )}
                {medicalClaimsTable?.length === 0 && (
                  <tr>
                    <td colSpan={5} className="text-center">
                      {t('noResults')}
                    </td>
                  </tr>
                )}
              </>
            }
          />
        </div>
      )}

      {isMobile &&
        (medicalClaimsTable &&
          medicalClaimsTable.predectiveSearches?.length > 0 ? (
          <div className="accordion-table">
            <Accordion
              initialActiveIndex={0}
              id="medical_claims_table"
              items={
                medicalClaimsTable &&
                medicalClaimsTable.predectiveSearches?.length > 0 &&
                medicalClaimsTable?.predectiveSearches?.map(
                  (item: any, i: any) => ({
                    title: (
                      <div data-testid={`claimIdLink${i}`}>
                        <span className="font-sixteen">
                          {t('claims.label.id')}
                        </span>
                        <div>
                          <button
                            className="darkblue-color bg-transparent border-0 text-decoration-underline p-0 font-sixteen redhat_regular"
                            onClick={() => {
                              setSearchParams({
                                startDate: fromDate,
                                endDate: toDate,
                                memberId: memberId,
                                claimtype: showMedical ? '0' : '1',
                                offset: String(currentPage),
                                limit: '10',
                              });
                              navigate(claimsSummary, {
                                state: {
                                  memberId: memberId,
                                  ClaimType: showMedical ? '0' : '1',
                                  endDate: toDate,
                                  startDate: fromDate,
                                  isEOB: item.eob,
                                  ClaimId: item.claimId,
                                  currentPage,
                                },
                              });
                            }}
                            role="link"
                          >
                            {item.claimId}
                          </button>
                        </div>
                      </div>
                    ),
                    id: 'medical_claims_table_data',
                    content: (
                      <div>
                        <div
                          data-testid={`serviceDateTab${i}`}
                          className="redhat_bold"
                        >
                          {t('claims.label.serviceDate')}
                        </div>
                        <div
                          data-testid={`medicalClaimsServiceDate${i}`}
                          className="mb-3"
                        >
                          {convertStringToDate(item.serviceDate)}
                        </div>
                        <div
                          data-testid={`statusTab${i}`}
                          className="redhat_bold"
                        >
                          {t('claims.label.claimStatus')}
                        </div>
                        <div
                          data-testid={`medicalClaimStatus${i}`}
                          className="mb-3"
                        >
                          {item.status}
                        </div>
                        <div
                          data-testid={`providerNameTab${i}`}
                          className="redhat_bold"
                        >
                          {t('claims.label.providerName')}
                        </div>
                        <div data-testid={`providerName${i}`} className="mb-3">
                          {item.providerName}
                        </div>
                        <div
                          data-testid={`viewEODTab${i}`}
                          className="redhat_bold"
                        >
                          {t('claims.label.vieweob')}
                        </div>
                        <div data-testid={`viewEOBIcon${i}`} className="mb-3">
                          {item.eob === 'true' ? (
                            <button
                              className="darkblue-color btn-links"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                eobPDF(item.claimId);
                              }}
                            >
                              <DownloadIcon color={darkbluecolor} />
                            </button>
                          ) : (
                            <label
                              className="visually-hidden"
                              aria-label="Download not available"
                            >
                              {t('claims.label.notApplicable')}
                            </label>
                          )}
                        </div>
                      </div>
                    ),
                  })
                )
              }
            />
          </div>
        ) : (
          medicalClaimsTable?.length === 0 && (
            <div className="mb-4">
              <div className="text-center">{t('noResults')}</div>
            </div>
          )
        ))}

      {totalItems > 10 && (
        <div className="d-flex align-items-center pt-2 pb-2 ps-2">
          {currentPage !== 1 && (
            <Button
              disabled={currentPage === 1}
              dataTestId="pagePreviousNavigateIcon"
              onClick={previousPage}
              className="d-flex align-items-center previous_icon page_button bg-transparent border-0 p-0"
            >
              {<RightArrowIcon />}
            </Button>
          )}
          <span
            data-testid="pagesCounts"
            className="redhat_regular font-fourteen fw-400 px-2"
          >
            <span className="pe-1">{t('claims.label.page')}</span>{' '}
            {`${currentPage} / ${maxPages}`}
          </span>
          {currentPage !== maxPages && (
            <Button
              disabled={false}
              onClick={nextPage}
              dataTestId="pageNavigateIcon"
              className="d-flex align-items-center next_icon page_button bg-transparent border-0 p-0"
            >
              {<RightArrowIcon />}
            </Button>
          )}
        </div>
      )}

      {/* To show the internalServerError message */}
      <CommonErrorMessage message={errorMessage} />

      {/* disclaimer Message */}
      {!isAvMed() && (
        <div className="black-color pt-4">
          <ul className="ps-4" data-testid="footerDisclaimer">
            <li className="mb-3" data-testid="disclaimer1">
              {' '}
              {t('claims.label.disclaimer1')}
            </li>
            {apiResponseSafeCheck(getPhone?.data) ? (
              <li className="mb-3" data-testid="disclaimer2">
                {t('claims.label.disclaimer21')}{' '}
                <ContactInfo
                  phoneData={{
                    phone1800: getPhone?.data?.benefitPhone1800,
                    phoneLocal: getPhone?.data?.benefitPhoneLocal,
                  }}
                />
                {t('claims.label.disclaimer22')}{' '}
              </li>
            ) : (
              <li className="mb-3" data-testid="disclaimer2">
                {t('claims.label.disclaimer23')}
              </li>
            )}
            <li data-testid="disclaimer3" className="mb-3">
              {t('claims.label.disclaimer3')}
            </li>
          </ul>
          <p>{t('claims.label.disclaimer4')}</p>
          <p className="mb-5" data-testid="disclaimer5">
            {t('claims.label.disclaimer5')}{' '}
            <Link
              to={`mailto:${t('claims.label.disclaimer6')}`}
              className="ws-nowrap text-decoration-underline"
              data-testid="customerMailId"
            >
              {t('claims.label.disclaimer6')}
            </Link>{' '}
            {apiResponseSafeCheck(getPhone?.data) ? (
              <>
                {t('claims.label.disclaimer7')}{' '}
                <ContactInfo
                  phoneData={{
                    phone1800: getPhone?.data?.benefitPhone1800,
                    phoneLocal: getPhone?.data?.benefitPhoneLocal,
                  }}
                />
              </>
            ) : null}
          </p>
        </div>
      )}
    </>
  );
};
export default MedicalClaims;
