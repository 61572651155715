import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import specific components from external UI library
import {
  Button,
  Headings,
  ModalOverlay,
} from '@sentaraui/optimahealth_web';

// Define the prop types for DeleteAccountPopup component
interface DeleteAccountPopupProps {
  isOpen: boolean; // Flag indicating if the modal is open
  closeModal: () => void; // Function to close the modal
  isShowContent: boolean; // Flag indicating if modal content should be shown
  variant: string; // Variant of the buttons
  deleteAccount: () => void; // Function to handle account deletion
  isConfirmOpen: boolean; // Flag indicating if the confirmation modal is open
  confirmDeleteAccount: () => void; // Function to confirm account deletion
}

// Define the DeleteAccountPopup component
function DeleteAccountPopup({
  isOpen,
  closeModal,
  isShowContent,
  variant,
  deleteAccount,
  isConfirmOpen,
  confirmDeleteAccount,
}: DeleteAccountPopupProps) {
  // Initialize translation hook
  const { t } = useTranslation();

  return (
      <div className="modal-wrapper" data-testid="modalWrapper">
        <ModalOverlay
          isOpen={isOpen}
          onClose={() => {
            closeModal();
          }}
          overlayInfo="Account delete overlay"
        >
          {/* Display content when isShowContent flag is true */}
          {isShowContent && (
            <div data-testid="accountDeleteModalTitle">
              <Headings
                className="redhat_regular font-twentyfour fw-700 mb-3 me-4"
                level={3}
                text={t('settings.label.accountDeleteModalTitle')}
              />
              {/* Description paragraphs */}
              <p className="mb-4" data-testid="accountDeleteModalDescription">
                {t('settings.label.accountDeleteModalDescription')}
              </p>
              <p className="mb-4" data-testid="accountDeleteModalDescription1">
                {t('settings.label.accountDeleteModalDescription1')}
              </p>
              <p className="mb-4" data-testid="accountDeleteModalDescription2">
                {t('settings.label.accountDeleteModalDescription2')}
              </p>
              <p className="mb-4" data-testid="accountDeleteModalDescription3">
                {t('settings.label.accountDeleteModalDescription3')}
              </p>
              <p className="mb-4" data-testid="accountDeleteModalDescription4">
                {t('settings.label.accountDeleteModalDescription4')}
              </p>
              <div className="d-flex flex-lg-row flex-md-row flex-column gap-lg-3 gap-md-3 gap-4 mb-2">
                <Button
                  dataTestId={'continueButton'}
                  variant={variant}
                  type="submit"
                  onClick={confirmDeleteAccount}
                >{t('settings.label.confirm')}
                </Button>
                <Button
                  dataTestId={'cancelButton'}
                  variant={'secondary_btn'}
                  onClick={() => {
                     closeModal()
                  }}
                >{t('activation.label.cancel')}
                </Button>
              </div>
            </div>
          )}
        </ModalOverlay>
      </div>
  );
}

export default DeleteAccountPopup;
