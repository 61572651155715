import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toSentancePascalCase, DeviceTypes, useDeviceType, getDecryptedValue, UserDetails } from 'common';
import {
  useMemberIDRequest,
  useCardsPdf,
} from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from 'context';
import {
  Checkbox,
  Button,
  ModalOverlay,
  AccordionTable,
  Headings,
  GlobalError,
  Loader,
  Table,
} from '@sentaraui/optimahealth_web';
import { PDFViewer, pdfViewerDefaultProps } from '../../organisms/pdfViewer';

// Initialize a temporary array to store checkbox selections
let _tempArr: any[] = [];

const MemberIdCardTable = ({
  mapState,
  setIsOpenFax,
  OrderMemberIDCard,
  faxIDCard,
}: any) => {
  //  Initialize useTranslation hook
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  // To manage the loader
  const [loader, setLoader] = useState(false);
  // To manages the error messages
  const [errorMessage, setErrorMessage] = useState(false);
  const [showBtn, setShowBtn] = useState<any>([]);
  // To manage PDF functionality
  const { CardsPdf } = useCardsPdf();
  const { primaryUser, loginMID, getFeature } = useContext(GlobalContext);
  const [pdfData, setPdfData] = useState<string | null>('')
  // To make phoneNumber CLickable

  // Checkbox change handler
  const checkBoxChange = (e: any, id: any) => {
    _tempArr = [...showBtn];
    if (e.target.checked) {
      _tempArr.push(id.mId);
      setShowBtn(_tempArr);
    } else {
      const temp = _tempArr.indexOf(id.mId);
      if (temp !== -1) {
        _tempArr = showBtn.filter(function (item: any) {
          return item !== id.mId;
        });
        setShowBtn(_tempArr);
      }
    }
    setErrorMessage(false);
  };




  const { memberIDRequestType } = useMemberIDRequest();
  const [formData, setFormData] = useState<any>();

  // Function to show member ID card
  const memberShowIdCard = async (e: any) => {
    e.preventDefault();
    if (showBtn.length > 0) {
      setLoader(true);
      setShowBtn(_tempArr);
    }
    const userId: string = await getDecryptedValue(UserDetails.usedId);

    const postData: any = {
      memberId: _tempArr,
      loginId: btoa(userId)
    };

    // Perform member ID request
    const response = await memberIDRequestType(postData);
    Object.keys(response).length > 0 && setFormData(response);
    setFormData(response.data?.message ? response.data?.message : null);
    setLoader(false);
    if (response.data) {
      setShowBtn(_tempArr);
    }
    setIsOpen(true);
    setErrorMessage(false);
  };

  const formatMessage = (message: string) => {
    const phoneRegex = /(\d-\d{3}-\d{3}-\d{4}|\d{3}-\d{3}-\d{4})/g;

    const paragraphs = message.split("<br>");

    return paragraphs.map((paragraph, index) =>
    (<p key={`${paragraph}-${index}`} className={index > 0 ? "mt-3" : ""}>
      {paragraph.split(phoneRegex).map((part, i) => {
        if (part.match(phoneRegex)) {
          return (
            <a
              key={`${part}-${i}`}
              href={`tel:${part.replace(/-/g, '')}`}
              className="pb-4 pt-2 black-color"
              data-testid={`phone_number_${index}`}
              >
              {part}
            </a>);
        }
        return part;
      })}
    </p>
    ));
  };

  // Function to convert PDF data and open/download PDF
  const pdfConverter = (data: any) => {
    try {
      const filename = 'MemberList.pdf';
      const binary = atob(
        data && data.data?.cardPdf?.replace?.(/([^\r])\n/g, '$1\r\n')
      );

      const nav = window.navigator as any;
      if (nav.msSaveOrOpenBlob) {
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([view], { type: 'application/pdf' });
        nav.msSaveOrOpenBlob(blob, filename);
      } else {
        setPdfData(binary)
      }
    }
    catch (error) {
      setErrorMessage(true);
    }
    finally {
      setLoader(false);
    }
  };
  //Function to show PDF card
  const pdfShowCard = async (Id: string) => {
    setErrorMessage(false);
    setLoader(true);
    const data = {
      memberId: btoa(Id),
    };

    const response = await CardsPdf('cardaspdf', data);
    if (response?.error) {
      setErrorMessage(true);
      setLoader(false);
    } else {
      pdfConverter(response);
    }
  };
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;


  if (!errorMessage && pdfData) {
    return <PDFViewer data={pdfData} onBack={() => setPdfData(null)} onError={() => { setPdfData(null); setErrorMessage(true) }}
      {...pdfViewerDefaultProps(t)}
    />
  }

  const MemberDetails = () => {
    return (
      mapState?.map((item: any, index: number) => (
        <tr key={item?.mId}>
          <td className="justify-content-start check-box-only">
            <Checkbox
              id="checkbox"
              dataTestId={`member_id_checkbox${index + 1}`}
              className="form-check-input mt-0"
              type="checkbox"
              disabled={false}
              onChange={(e: any) => checkBoxChange(e, item)}
            />
          </td>

          <td className="align-middle">
            <strong>{toSentancePascalCase(item.memberName)}</strong>
            <span className="redhat_regular">
              {primaryUser === "P" && loginMID === item.mId
                ? ' (' + t('claims.label.primary') + ')'
                : ''}
            </span>
          </td>
          <td className="align-middle">{item.memberId}</td>

          <td>
            <div className="d-flex justify-content-end px-4 member-id-card-btn">
              <Button
                dataTestId={`view_button${index + 1}`}
                id={`representavieButton_${index}`}
                onClick={() => {
                  pdfShowCard(item.memberId);
                }}
                disabled={![...showBtn].includes(item.mId)}
                variant={
                  [...showBtn].includes(item.mId)
                    ? 'secondary_btn btn-medium w-100'
                    : 'secondary_btn btn-medium w-100 disabled_btn pointer-none'
                }
              >
                {t('memberIDCard.label.viewPrint')}
              </Button>
            </div>
          </td>
        </tr>
      ))
    )
  }
  const headerRows = [
    { text: t('memberIDCard.label.select') },
    { text: t('memberIDCard.label.memberName') },
    { text: t('memberIDCard.label.memberID') },
    {
      text: <span className="visually-hidden">
        {t('memberIDCard.label.viewPrintIDCard')}
      </span>
    }
  ]

  return (
    <>
      {loader && <Loader />}
      {errorMessage && (
        <GlobalError variant="mb-3" dataTestId="memberIdCardError">
          {t('viewIDCard.errors.memberIdCardError')}
        </GlobalError>
      )}

      <div className="heading-one-semibold mb-4">
        <Headings
          level={1}
          text={t('navigation.label.memberIDCard')}
          dataTestId="memberIdCardHeader"
        />
      </div>

      <div>
        {!isMobile && (
          <Table header={headerRows} rowsChildren={MemberDetails()} />
        )}
        {isMobile &&
          (mapState?.length > 0 ? (
            <div className={(getFeature?.printIDCard) ? "accordion-table mb-4"
              : "accordion-table accordion-table-disabled mb-4 "}>
              <AccordionTable
                initialActiveIndex={0}
                id="member_id_card_table"
                items={
                  mapState?.length > 0 &&
                  mapState?.map((item: any, i: any) => ({
                    title: (
                      <div className="d-flex">
                        <div data-testid={`authNumberTab${i}`}>
                          <span className="font-sixteen">
                            <Checkbox
                              id="checkbox"
                              dataTestId={`member_id_checkbox${i + 1}`}
                              className="form-check-input mt-0"
                              type="checkbox"
                              disabled={false}
                              onChange={(e: any) => checkBoxChange(e, item)}
                            />
                          </span>
                        </div>
                        <div>
                          <div>
                            {toSentancePascalCase(item.memberName)}
                            <span className="redhat_regular">
                              {item.memberId.includes('*01')
                                ? ' (' + t('claims.label.primary') + ')'
                                : ''}
                            </span>
                          </div>
                          <div className="redhat_regular">{item.memberId}</div>
                        </div>
                      </div>
                    ),
                    id: 'member_id_card_table_data',
                    content: (
                      <>
                        {(getFeature?.printIDCard) ? (
                          <div className="mb-3">
                            <Button
                              dataTestId={`view_button${i + 1}`}
                              id={`representavieButton_${i}`}
                              onClick={() => {
                                pdfShowCard(item.memberId);
                              }}
                              disabled={![...showBtn].includes(item.mId)}
                              variant={
                                [...showBtn].includes(item.mId)
                                  ? 'secondary_btn btn-medium w-100'
                                  : 'secondary_btn btn-medium w-100 disabled_btn pointer-none'
                              }
                            >
                              {t('memberIDCard.label.viewPrint')}
                            </Button>
                          </div>
                        ) : (null)}
                      </>
                    ),
                  }))
                }
              />
            </div>
          ) : (
            <div className="mb-4">
              <div className="text-center">{t('noResults')}</div>
            </div>
          ))}
        <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-4 member-id-card-btn">
          {OrderMemberIDCard && (
            <Button
              dataTestId="requestid_card_button"
              id="primary"
              onClick={(e: any) => memberShowIdCard(e)}
              variant={showBtn?.length >= 1 ? 'primary_btn' : 'disabled_btn'}
              disabled={showBtn?.length >= 1 ? false : true}
            >
              {t('memberIDCard.label.requestIDCard')}
            </Button>
          )}
          {faxIDCard ? (
            <Button
              dataTestId="fax_button"
              id="primary"
              onClick={() => {
                setIsOpenFax();
                setErrorMessage(false);
              }}
              variant={showBtn?.length >= 1 ? 'primary_btn' : 'disabled_btn'}
              disabled={showBtn?.length >= 1 ? false : true}
            >
              {t('memberIDCard.label.fax')}
            </Button>
          ) : null}
        </div>
        {formData && (
          <ModalOverlay
            isOpen={isOpen}
            onClose={setIsOpen}
            overlayInfo="Modal information"
          >
            <h5
              data-testid="PleaseNoteTitle"
              className="memoverlay-title redhat_medium mb-2"
            >
              {t('memberIDCard.label.pleaseNote')}
            </h5>
            <div data-testid="requestIdNote">{formatMessage(formData)} </div>
            <div className="d-flex justify-content-start mt-4">
              <Button
                dataTestId="close_button"
                id="primary"
                onClick={() => setIsOpen(false)}
                variant="primary_btn"
              >
                {t('activation.label.buttonOk')}
              </Button>
            </div>
          </ModalOverlay>
        )}
      </div>
    </>
  );
};

export default MemberIdCardTable;